/**
 * API call for the site management services
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SiteManagementAPIConstant } from 'src/app/constant/api.constants';
import { environment } from 'src/environments/environment';
import {
  SiteDetailApiResponse,
  SiteCreateAPIRequest,
  SiteListApiResponse,
  SiteListResponse,
  SiteDetail,
} from '../models/sites.model';
import { IDropdown, SortSearchAndPaginationModal } from '@models/common.model';

@Injectable({
  providedIn: 'root',
})
export class SiteAPIService {
  constructor(private http: HttpClient) {}

  /** List **/
  getSites(param: SortSearchAndPaginationModal): Observable<SiteListResponse> {
    const queryParams: Record<string, string | number | undefined> = {
      sortDir: param.pagination?.sortDir || 'ASC',
      pageNumber: param.pagination?.currentPage || 0,
      pageSize: param.pagination?.pageSize || 20,
      queryString: param.search?.toString() || '',
      type: param.filter?.siteType || '',
    };

    const queryString = Object.entries(queryParams)
      .filter(([, value]) => !!value)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return this.http.get<SiteListApiResponse>(
      `${environment.apiBaseUrl}${SiteManagementAPIConstant.SITE_LIST}?${queryString}`
    ).pipe(
      map(apiResponse => ({
        data: apiResponse.items,
        meta: {
          itemCount: apiResponse.totalCount,
          pageCount: apiResponse.totalPages,
          currentPage: apiResponse.pageNumber,
          pageSize: queryParams['pageSize'] as number,
        },
      }))
    );
  }

  /** Add */
  addSite(param: SiteCreateAPIRequest) {
    return this.http.post<SiteDetailApiResponse>(
      environment.apiBaseUrl + SiteManagementAPIConstant.SITE_LIST,
      param
    );
  }

  /** Get */
  getSiteDetail(id: string | number) {
    return this.http.get<SiteDetailApiResponse>(
      environment.apiBaseUrl + SiteManagementAPIConstant.SITE_LIST + '/' + id
    );
  }

  /** Update */
  updateSiteDetail(id: string | number, param: SiteCreateAPIRequest) {
    return this.http.patch<SiteDetailApiResponse>(
      environment.apiBaseUrl + SiteManagementAPIConstant.SITE_LIST + '/' + id,
      param
    );
  }

  /** Delete */
  deleteSiteDetail(id: string | number) {
    return this.http.delete<SiteDetailApiResponse>(
      environment.apiBaseUrl + SiteManagementAPIConstant.SITE_LIST + '/' + id
    );
  }
  /** Dropdown List **/
  getSiteDropdownList(
    addCodeToName?: boolean
  ): Observable<Partial<IDropdown>[]> {
    return this.http
      .get<SiteListApiResponse>(
        environment.apiBaseUrl +
          SiteManagementAPIConstant.SITE_LIST +
          `?pageSize=-1`
      )
      .pipe(
        map((apiResponse: SiteListApiResponse) => {
          let sites: SiteDetail[] = apiResponse.items;
          let siteDropDown: IDropdown[] = [];
          sites.forEach((site) => {
            siteDropDown.push({
              id: site.id,
              name: addCodeToName
                ? `${site.code} - ${site.displayName}`
                : site.displayName,
            });
          });
          siteDropDown.sort((a, b) => a.name.localeCompare(b.name));
          return siteDropDown;
        })
      );
  }

}
